import { batch } from 'react-redux';

import { getColor } from 'basic-components';
import { ActionService, DataStorageApi, dateBetweenTypes, TimeService, tsDataRangesObject, tsKeysTypes } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';
import getApiConfig from 'api/ApiConfig';
import FORMULAS from 'resources/constants/Formulas.js';
import { CurvesUtils, GAUtils, LocalStorageUtils } from 'utils';

import { MetadataLabel } from 'primary/TsSettings';

import dayjs from 'dayjs';

export default (() => {
  const SYMBOL = "Symbol",
        PRODUCT = "Product",
        ROOT = "FuturesRoot"
  
  const ON_CHANGE = 'ON_CHANGE_BASIC_CURVES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_BASIC_CURVES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const editFormula = ({ address, ...params }) => dispatch => {
    dispatch(clear())
    Promise.resolve().then(() => {
      batch(() => {
        CurvesUtils.PLAIN_PARSED_CURVE_PARAMS.filter(param => params[param]).forEach(param => dispatch(onChange(params[param], param)))
        if(params.startDate === dateBetweenTypes.today) {
          dispatch(onChange(dateBetweenTypes.today, "fromSelected"))
          dispatch(onChange(undefined, "from"))
          dispatch(onChange(undefined, "fromTime"))
        } else if(params.startDate) {
          dispatch(onChange(dateBetweenTypes.date, "fromSelected"))
          dispatch(onChange(String(params.startDate).split("T")[0], "from"))
          dispatch(onChange(String(params.startDate).split("T")[1], "fromTime"))
        }
        if(params.endDate === dateBetweenTypes.today) {
          dispatch(onChange(dateBetweenTypes.today, "toSelected"))
          dispatch(onChange(undefined, "to"))
          dispatch(onChange(undefined, "toTime"))
        } else if(params.endDate) {
          dispatch(onChange(dateBetweenTypes.date, "toSelected"))
          dispatch(onChange(String(params.endDate).split("T")[0], "to"))
          dispatch(onChange(String(params.endDate).split("T")[1], "toTime"))
        }
        dispatch(onChange(address, "address"))
      })
    })
  }

  const getKeysForGroup = ({ input, callsRefs }) => (dispatch, getState) => {
    const groupName = getState().curvesState.basicState.groupName
    if(!groupName) {
      return Promise.resolve().then(() => [])
    }
    callsRefs.current[0]?.cancel?.()
    callsRefs.current[0] = 
      new DataStorageApi(dispatch(getApiConfig()))
        .getSymbolKeys(groupName, input ? `*${input}*` :  "*", { from: 0, size: 100, metadata: true, useEs: true })
        .cancelable(true)
        .noFetching()
        .build()
        .call()

    return callsRefs.current[0].promise.then((response = {}) => 
      (response?.items || []).map(item => ({ 
        value: item.name, 
        label: item.type === tsKeysTypes.METADATA ? (<MetadataLabel name={item.name}/>) : item.name,
        color: item.type === tsKeysTypes.METADATA ? getColor("grey-dark") : undefined,
        type: `${item.type}_KEY`
      })))
  }

  const saveDefaultSettings = () => (_, getState) => {
    // eslint-disable-next-line no-unused-vars
    const { csvHeaders, dateFormat, order, timeZone, range, lastTypeAmount, lastType, fromSelected, from, fromTime, toSelected, to, toTime } = getState().curvesState.basicState
    LocalStorageUtils.setCurveDefaultSettings({ csvHeaders, dateFormat, order, timeZone, range, lastTypeAmount, lastType, fromSelected, from, fromTime, toSelected, to, toTime })
  }

  const addData = () => (dispatch, getState) => {
    GAUtils.sendEvent({
      category: "action",
      action: "add_curve",
      label: "add"
    })
    const paramsExceedingLength = []
    let formula = `=${FORMULAS.LOAD_FC}(`
    const { address, groupName, column, product, root, symbolSize, timeZone, range, from, to, fromSelected, toSelected, fromTime, toTime, lastType, lastTypeAmount, dateFormat, order, formatType, csvHeaders } = getState().curvesState.basicState

    formula += listTimeseriesActions.addAndValidateParam({ paramName: "groupName", paramValue: groupName, paramsExceedingLength, withComma: false })
    formula += listTimeseriesActions.addAndValidateParam({ paramName: "column", paramValue: column, paramsExceedingLength })
    formula += listTimeseriesActions.addAndValidateParam({ paramName: "product", paramValue: product, paramsExceedingLength })
    formula += listTimeseriesActions.addAndValidateParam({ paramName: "root", paramValue: root, paramsExceedingLength })

    if(symbolSize) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "symbolSize", paramValue: symbolSize, paramsExceedingLength })
    }
    if(timeZone) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "timeZone", paramValue: timeZone, paramsExceedingLength })
    }
    if(dateFormat) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "dateFormat", paramValue: dateFormat, paramsExceedingLength })
    }
    if(range) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "range", paramValue: range, paramsExceedingLength })
    }
    if(range === tsDataRangesObject.between) {
      const startDateValue = fromSelected === dateBetweenTypes.date ? dayjs(from + (fromTime ? "T" + fromTime : "T00:00:00.000")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : fromSelected
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "startDate", paramValue: startDateValue, paramsExceedingLength })
      const endDateValue = toSelected === dateBetweenTypes.date ? dayjs(to  + (toTime ? "T" + toTime : "T23:59:59.999")).format(TimeService.ISO_DATE_TIME_FORMAT_BACKEND_WITH_SECONDS_STYLE) : toSelected
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "endDate", paramValue: endDateValue, paramsExceedingLength })
    }
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "lastType", paramValue: lastType, paramsExceedingLength })
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "lastTypeAmount", paramValue: lastTypeAmount, paramsExceedingLength })
    }
    if(order) {
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "order", paramValue: order, paramsExceedingLength })
    }
    if(csvHeaders) {
      const prepColumHeader = listTimeseriesActions.prepColumnHeaders({ csvHeaders, formatType })
      formula += listTimeseriesActions.addAndValidateParam({ paramName: "csvHeaders", paramValue: `""[${prepColumHeader}]""`, paramsExceedingLength })
    }
    formula += ")"
    const setShowTooLongFormula = val => dispatch(onChange(val, "showTooLongFormula"))
    const setAddress = addressLocal => dispatch(onChange(addressLocal, "address"))
    listTimeseriesActions.formulaValidator({ formula, paramsExceedingLength, address, setShowTooLongFormula, setAddress })
  }

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    editFormula,
    saveDefaultSettings,
    getKeysForGroup,
    addData,
    SYMBOL,
    PRODUCT,
    ROOT
  }
})()