import properties from 'resources/constants/properties.json';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
export default(() => ({
  LOAD_DATAPREP: `${FUNCTIONS_NAMESPACE}.GET.DATAPREP`,
  LOAD_FILE: `${FUNCTIONS_NAMESPACE}.GET.FILE`,
  LOAD_TS: `${FUNCTIONS_NAMESPACE}.GET.TS`,
  LOAD_FC: `${FUNCTIONS_NAMESPACE}.GET.FC`,
  REFRESH: `${FUNCTIONS_NAMESPACE}.REFRESH`,
  REFRESH_ALL: `${FUNCTIONS_NAMESPACE}.REFRESH.ALL`,
}))()