import { globalActions, loginActions } from 'primary-components';

import properties from 'resources/constants/properties.json';
import { AuthSingleton, LocalStorageUtils } from 'utils';
import { throttledFetch } from 'utils/ThrottleUtils';

// we can not use one from AuthUtils
// const enableLogInButton = async () => {
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: true })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.DataLakeButton", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group1", buttonId: "NG.TimeSeriesButton", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshCell", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshSheet", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.RefreshAll", enabled: false })
//   await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group2", buttonId: "NG.UploadButton", enabled: false })
// }

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const getAuthToken = () => (dispatch, getState) => getState().primaryComponentsState.loginState.token 

const getApiConfig = () => dispatch => ({                  
  getAuthToken: () => dispatch(getAuthToken()),
  handleRequest: (...params) => dispatch(globalActions.handleRequest(...params)),
  onLogOut: () => {
    LocalStorageUtils.clearLocalStorage()
    window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(loginActions.logOutResponse())
    // enableLogInButton()
  },
  baseUrl: AuthSingleton.getInstance().getApiUrl(),
  clearLocalStorage: () => {
    LocalStorageUtils.clearLocalStorage()
    window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(loginActions.logOutResponse())
    // enableLogInButton()
  },
  noCors: true,
  fetch: throttledFetch
})

export default getApiConfig;