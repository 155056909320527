import PropTypes from 'prop-types';

import { Clear, Settings as SettingsIcon } from 'basic-components';

import './ExpandedSettings.scss';

const ExpandedSettings = ({ closeSettings, children }) => (
  <div className="ng-office-app__authed__content__body__item__expanded-settings">
    <div className="ng-office-app__authed__content__body__item__expanded-settings__top">
      <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left">
        <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left__icon">
          <SettingsIcon
              color="grey-medium"
              height={16}
              width={16}
          />
        </div>
        <div className="ng-office-app__authed__content__body__item__expanded-settings__top__left__label">
          Settings
        </div>
      </div>
      <div 
          className="ng-office-app__authed__content__body__item__expanded-settings__top__right"
          onClick={closeSettings}
      >
        <Clear
            color="grey-medium"
            height={10}
            width={10}
        />
      </div>
    </div>
    <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom">
      {children}
    </div>
  </div>
)

ExpandedSettings.propTypes = {
  children: PropTypes.any.isRequired,
  closeSettings: PropTypes.func.isRequired,
}

export default ExpandedSettings;