import PropTypes from 'prop-types';

import { ArrowDown, Settings as SettingsIcon } from 'basic-components';

import './CollapsedSettings.scss';

const CollapsedSettings = ({ openSettings }) => (
  <div 
      className="ng-office-app__authed__content__body__item__collapsed-settings"
      onClick={openSettings}
  >
    <div className="ng-office-app__authed__content__body__item__collapsed-settings__icon">
      <SettingsIcon
          color="grey-medium"
          height={16}
          width={16}
      />
    </div>
    <div className="ng-office-app__authed__content__body__item__collapsed-settings__label">
      Settings
    </div>
    <div className="ng-office-app__authed__content__body__item__collapsed-settings__arrow">
      <ArrowDown
          color="blue-bright"
          height={24}
          width={24}
      />
    </div>
  </div>
)

CollapsedSettings.propTypes = {
  openSettings: PropTypes.func.isRequired
}

export default CollapsedSettings;