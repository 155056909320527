import PropTypes from 'prop-types';

import './SectionTitle.scss';

const SectionTitle = ({ children }) => (
  <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__section-title">
    {children}
  </div>
)

SectionTitle.propTypes = {
  children: PropTypes.string.isRequired
}

export default SectionTitle;